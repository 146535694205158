import axios from "axios";

interface HeartBeatResponse {
    success: boolean;
    data?: any;
}

export const heartBeat = (cookie) =>
    new Promise<HeartBeatResponse>((resolve) => {
        if (!cookie) {
            return resolve({success: false});
        }
        // Let's try to login
        axios.post(`${process.env.baseUrl}/heartbeat`, {
            token: cookie,
        }).then((result) => {
            resolve(result.data);
        }).catch(() => {
            resolve({success: false});
        });
    });
